/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Unknown, 1 = Ready, 10 = Creating, 11 = Updating, 12 = Deleting, 20 = ErrorCreating, 21 = ErrorUpdating, 22 = ErrorDeleting
 */
export enum SceneStatus {
    Unknown = 0,
    Ready = 1,
    Creating = 10,
    Updating = 11,
    Deleting = 12,
    ErrorCreating = 20,
    ErrorUpdating = 21,
    ErrorDeleting = 22
}

